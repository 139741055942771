<template lang="pug">
  div.banner-block.text-center.pb-3.pt-3
    p.text-uppercase.font-weight-medium.d-flex.flex-row.justify-center.align-center
      router-link(:to="{ name: 'DemoPage' }") {{ $t('tryNowOurDemo') }}
</template>

<script>
export default {
  name: 'BannerBlock'
}
</script>
